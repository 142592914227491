import store from "../store";

const admin_routes = [
  {
    path: "",
    component: () => import("../app/admin/dashboard/Dashboard.vue"),
    meta: {
      title: "Dashboard",
    },
  },
  {
    path: "website_settings",
    component: () =>
      import("../app/admin/site-settings/settings-main/settings-main.vue"),
    meta: {
      title: "Website Settings",
    },
  },
  {
    path: "manage_orders/pending_orders",
    name: "manage_orders.pending_orders",
    component: () =>
      import("../app/admin/manage-orders/pending-orders/pending-orders.vue"),
    meta: {
      title: "Pending Orders",
    },
  },
  {
    path: "manage_orders/order_history",
    name: "manage_orders.order_history",
    component: () =>
      import("../app/admin/manage-orders/order-history/order-history.vue"),
    meta: {
      title: "Order History",
    },
  },
  {
    path: "manage_orders/order_details/:id",
    name: "manage_orders.order_details",
    component: () =>
      import("../app/admin/manage-orders/order-details/order-details.vue"),
    meta: {
      title: "Order Details",
    },
  },
  {
    path: "manage_agent_orders/pending_orders",
    name: "manage_agent_orders.pending_orders",
    component: () =>
      import(
        "../app/admin/manage-agent-orders/pending-orders/pending-orders.vue"
      ),
    meta: {
      title: "Pending Orders",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.is_admin_site) {
        next();
      } else {
        next("/admin");
      }
    },
  },
  {
    path: "manage_agent_orders/order_history",
    name: "manage_agent_orders.order_history",
    component: () =>
      import("../app/admin/manage-agent-orders/OrderHistory.vue"),
    meta: {
      title: "Order History",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.is_admin_site) {
        next();
      } else {
        next("/admin");
      }
    },
  },
  {
    path: "manage_agent_orders/order_details/:id",
    name: "manage_agent_orders.order_details",
    component: () =>
      import(
        "../app/admin/manage-agent-orders/order-details/order-details.vue"
      ),
    meta: {
      title: "Order Details",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.is_admin_site) {
        next();
      } else {
        next("/admin");
      }
    },
  },
  {
    path: "agent_manager/agency_invoices",
    component: () =>
      import("../app/admin/agent-manager/agency-invoices/agency-invoices.vue"),
    meta: {
      title: "Agency Invoices",
    },
  },
  {
    path: "agent_manager/agent_balance",
    component: () =>
      import("../app/admin/agent-manager/agent-balance/AgentBalance.vue"),
    meta: {
      title: "Agent Balance",
    },
  },
  {
    path: "agent_manager/agent_bulk_email",
    component: () =>
      import("../app/admin/agent-manager/agent-bulk-email/AgentBulkEmail.vue"),
    meta: {
      title: "Agent Bulk Email",
    },
  },
  {
    path: "agent_manager/agents_list",
    component: () =>
      import("../app/admin/agent-manager/agents-list/agents-list.vue"),
    meta: {
      title: "Agents List",
    },
  },
  {
    path: "vehicle_statistics/variant",
    component: () =>
      import("../app/admin/vehicle-statistics/variants/variants.vue"),
    meta: {
      title: "Variants",
    },
  },
  {
    path: "vehicle_statistics/manufacture",
    component: () =>
      import("../app/admin/vehicle-statistics/manufacturers/manufacturers.vue"),
    meta: {
      title: "Manufactures",
    },
  },
  {
    path: "vehicle_statistics/generation",
    component: () =>
      import("../app/admin/vehicle-statistics/generations/generations.vue"),
    meta: {
      title: "Generations",
    },
  },
  {
    path: "vehicle_statistics/model",
    component: () =>
      import("../app/admin/vehicle-statistics/vmodels/vmodels.vue"),
    meta: {
      title: "Models",
    },
  },
  {
    path: "vehicle_statistics/type",
    component: () =>
      import("../app/admin/vehicle-statistics/vtypes/vtypes.vue"),
    meta: {
      title: "Vehicle Types",
    },
  },
  {
    path: "vehicle_statistics",
    component: () =>
      import("../app/admin/vehicle-statistics/VehicleStatistics.vue"),
    meta: {
      title: "Vehicle Statistics",
    },
  },
  {
    path: "tuning_service/services",
    component: () => import("../app/admin/tuning_service/Services.vue"),
    meta: {
      title: "Services",
    },
  },
  {
    path: "tuning_service/options",
    component: () => import("../app/admin/tuning_service/Options.vue"),
    meta: {
      title: "Options",
    },
  },
  {
    path: "tuning_service/special-ecu-brands",
    component: () =>
      import(
        "../app/admin/tuning_service/special-ecu-brands/special-ecu-brands.vue"
      ),
    meta: {
      title: "Special ECU Brands",
    },
  },
  {
    path: "access_api",
    component: () => import("../app/admin/api-access/api-access.vue"),
    meta: {
      title: "Api Access",
    },
  },
  {
    path: "shop_manager/statistics",
    component: () => import("../app/admin/shop-manager/Statistics.vue"),
    meta: {
      title: "Shop Statistics",
    },
  },
  {
    path: "shop_manager/settings",
    component: () => import("../app/admin/shop-manager/ShopSettings.vue"),
    meta: {
      title: "Shop Settings",
    },
  },
  {
    path: "shop_manager/orders",
    component: () => import("../app/admin/shop-manager/OrderList.vue"),
    meta: {
      title: "Orders",
    },
  },
  {
    path: "shop_manager/order/:order_id",
    name: "view_order",
    component: () =>
      import("../app/admin/shop-manager/view-order/view-order.vue"),
    meta: {
      title: "Order | Shop",
    },
  },
  {
    path: "shop_manager/products",
    name: "product_list",
    component: () => import("../app/admin/shop-manager/ProductList.vue"),
    meta: {
      title: "Products",
    },
  },
  {
    path: "price-list",
    name: "price_list",
    component: () => import("../app/admin/price-list/PriceList.vue"),
    meta: {
      title: "Price List",
    },
  },
  {
    path: "shop_manager/categories",
    component: () => import("../app/admin/shop-manager/CategoryList.vue"),
    meta: {
      title: "Categories",
    },
  },
  {
    path: "shop_manager/coupons",
    component: () => import("../app/admin/shop-manager/CouponList.vue"),
    meta: {
      title: "Coupons",
    },
  },
  {
    path: "shop_manager/shipping_methods",
    component: () => import("../app/admin/shop-manager/ShippingMethods.vue"),
    meta: {
      title: "Shipping Methods",
    },
  },
  {
    path: "ecu-and-adblue-locations",
    component: () =>
      import(
        "../app/admin/miscellanceous/ecu-adblue-locations/ecu-adblue-locations.vue"
      ),
    meta: {
      title: "ECU & AdBlue Locations",
    },
  },
  {
    path: "bosch_codes",
    component: () => import("../app/admin/miscellanceous/BoschCode.vue"),
    meta: {
      title: "Bosch Codes",
    },
  },
  {
    path: "p_codes",
    component: () => import("../app/admin/miscellanceous/PCode.vue"),
    meta: {
      title: "P Codes",
    },
  },
  {
    path: "df_to_p_codes",
    component: () => import("../app/admin/miscellanceous/DFtoPCode.vue"),
    meta: {
      title: "DF to P Codes",
    },
  },
  {
    path: "downloads",
    component: () => import("../app/admin/miscellanceous/FileLink.vue"),
    meta: {
      title: "Downloads",
    },
  },
  {
    path: "terms_and_conditions",
    component: () =>
      import("../app/admin/terms-and-conditions/TermsAndConditions.vue"),
    meta: {
      title: "Terms And Conditions",
    },
  },
  {
    path: "chat_support",
    component: () => import("../app/admin/chat-support/ChatSupport.vue"),
    meta: {
      title: "Chat Support",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.live_support_public) {
        next();
      } else {
        next("/admin");
      }
    },
  },
  {
    path: "all_tickets",
    component: () => import("../app/admin/ticket_service/AllTickets.vue"),
    meta: {
      title: "All Tickets",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.ticket_system_public) {
        next();
      } else {
        next("/admin");
      }
    },
  },
  {
    path: "ticket_details/:id",
    component: () => import("../app/admin/ticket_service/TicketDetails.vue"),
    meta: {
      title: "Ticket Details",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.ticket_system_public) {
        next();
      } else {
        next("/admin");
      }
    },
  },
  {
    path: "user_profile",
    component: () => import("../app/admin/user-profile/UserProfile.vue"),
    meta: {
      title: "User Profile",
    },
  },
  {
    path: "profile_settings",
    component: () =>
      import("../app/admin/profile-settings/profile-settings.vue"),
    meta: {
      title: "Profile Settings",
    },
  },
  {
    path: "reg_searches",
    component: () => import("../app/admin/reg-searches/reg-searches.vue"),
    meta: {
      title: "reg search",
    },
  },
];

export default admin_routes;
