import store from "../store";

const user_routes = [
  {
    path: "",
    name: "submit_order",
    component: () => import("../app/users/submit-new/submit-new.vue"),
    meta: {
      title: "Submit New",
    },
  },
  {
    path: "your_order",
    name: "your_order",
    component: () => import("../app/users/your-orders/your-orders.vue"),
    meta: {
      title: "Your Order ",
    },
  },
  {
    path: "order_details/:id",
    name: "order_details",
    component: () => import("../app/users/order-details/order-details.vue"),
    meta: {
      title: "Order Details",
    },
  },
  {
    path: "buy_credits",
    name: "buy_credits",
    component: () => import("../app/users/buy-credits/buy-credits.vue"),
    meta: {
      title: "Buy Credits",
    },
  },
  // {
  //   path: "shop",
  //   name: "shop",
  //   component: () => import("../views/users/shop/ShopHome.vue"),
  //   meta: {
  //     title: "Home | Shop",
  //   },
  // },
  {
    path: "quotes-through-api",
    name: "quotes_through_api",
    component: () =>
      import("../app/users/quotes-through-api/quotes-through-api.vue"),
    meta: {
      title: "Quotes",
    },
  },
  {
    path: "shop/cart",
    name: "shopping_cart",
    component: () => import("../app/users/shop/shopping-cart/ShoppingCart.vue"),
    meta: {
      title: "Cart | Shop",
    },
  },
  // {
  //   path: "shop/product/:slug",
  //   name: "product",
  //   component: () => import("../views/users/shop/ViewProduct.vue"),
  // },
  {
    path: "shop/orders",
    name: "shop_orders",
    component: () => import("../app/users/shop/order-list/OrderList.vue"),
    meta: {
      title: "Orders | Shop",
    },
  },
  {
    path: "shop/order/:invoice_no",
    name: "view_order",
    component: () => import("../app/users/shop/view-order/ViewOrder.vue"),
    meta: {
      title: "Order | Shop",
    },
  },
  {
    path: "shop/checkout",
    name: "checkout",
    component: () => import("../app/users/shop/checkout-page/CheckoutPage.vue"),
    meta: {
      title: "Checkout | Shop",
    },
  },
  {
    path: "shop/checkout/:status/:invoice_no?",
    name: "checkout_success",
    component: () =>
      import("../app/users/shop/checkout-status/CheckoutStatus.vue"),
    meta: {
      title: "Checkout status | Shop",
    },
  },
  // {
  //   path: "shop/terms-and-conditions",
  //   name: "terms_and_conditions",
  //   component: () => import("../views/users/shop/TermsAndConditions.vue"),
  //   meta: {
  //     title: "Terms & Conditions | Shop",
  //   }
  // },
  {
    path: "vehicle_statistics",
    name: "vehicle_statistics",
    props: true,
    component: () =>
      import("../app/users/vehicle-statistics/vehicle-statistics.vue"),
    meta: {
      title: "Vehicle Statistics",
    },
  },
  {
    path: "ecu-adblue-locations",
    name: "ecu-adblue-locations",
    props: true,
    component: () =>
      import("../app/users/ecu-adblue-locations/ecu-adblue-locations.vue"),
    meta: {
      title: "ECU & Adblue Locations",
    },
  },
  {
    path: "eco_calculator",
    name: "eco_calculator",
    props: true,
    component: () => import("../app/users/eco-calculator/eco-calculator.vue"),
    meta: {
      title: "ECO Calculator",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.eco_calculator_public) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "access_api",
    name: "access_api",
    component: () => import("../app/users/api-access/api-access.vue"),
    meta: {
      title: "Api Access",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.api_access) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "p_codes_bosch_codes",
    name: "p_codes_bosch_codes",
    component: () => import("../app/users/code-services/code-services.vue"),
    meta: {
      title: "P Codes/ Bosch Codes",
    },
    // beforeEnter: async (to, from, next) => {
    //   if (store.state.settings.codes_access) {
    //     next();
    //   } else {
    //     next("/");
    //   }
    // }
  },
  {
    path: "file_upload",
    name: "file_upload",
    component: () => import("../app/users/file-upload/file-upload.vue"),
    meta: {
      title: "File Upload",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.file_upload_public) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "terms_and_conditions",
    name: "terms_and_conditions",
    component: () =>
      import("../app/users/terms-and-conditions/terms-and-conditions.vue"),
    meta: {
      title: "Terms And Conditions",
    },
  },
  {
    path: "chat_support",
    name: "chat_support",
    component: () => import("../app/users/chat-support/chat-support.vue"),
    meta: {
      title: "Chat Support",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.live_support_public) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "open_ticket",
    name: "open_ticket",
    component: () => import("../app/users/open-ticket/open-ticket.vue"),
    meta: {
      title: "Open Ticket",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.ticket_system_public) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "my_tickets",
    name: "my_tickets",
    component: () => import("../app/users/my-tickets/my-tickets.vue"),
    meta: {
      title: "My Ticket",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.ticket_system_public) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "ticket_details/:id",
    name: "ticket_details",
    component: () => import("../app/users/ticket-details/ticket-details.vue"),
    meta: {
      title: "Ticket Details",
    },
    beforeEnter: async (to, from, next) => {
      if (store.state.settings.ticket_system_public) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "user_profile",
    name: "user_profile",
    component: () => import("../app/users/user-profile/user-profile.vue"),
    meta: {
      title: "User Profile",
    },
  },
  {
    path: "profile_settings",
    name: "profile_settings",
    component: () =>
      import("../app/users/profile-settings/profile-settings.vue"),
    meta: {
      title: "Profile Settings",
    },
  },
  {
    path: "reg_searches",
    component: () => import("../app/users/reg-searches/reg-searches.vue"),
    meta: {
      title: "reg search",
    },
  },
];

export default user_routes;
